







import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import PayeesTableComponent from '../components/PayeesTableComponent.vue';
import { VendorCategory } from '../components/PayeeHelper';

@Component({
  components: {
    PayeesTableComponent,
  },
})
export default class VendorsVue extends BaseComponent {
  protected baseRoute: string = '/vendors';
  private category = VendorCategory.None;
}
